<template>
  <div
    style="float:leftwidth:100%;position: fixed;
  top: 1rem;"
  >
    <van-row
      class="main"
      style="float:left;border-bottom:1px solid #d3d2d2;height:5rem"
    >
      <van-col span="7" offset="1">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%;height:80%"
        />
      </van-col>
      <van-col span="15" offset="1" class="headtext">
        投诉建议中心
      </van-col>
    </van-row>
    <van-form @submit="onSubmit" style="float:left">
      <div style="margin: 16px;margin-top:20px;font-size:0.9rem">
        <font color="red">查看反馈结果</font
        >温馨提示:请详细填写具体投诉建议,一经核实或采纳,我司必定认真对待,给您满意的答复,谢谢您的信任与支持。
      </div>
      <van-field v-model="form.contactPhone" placeholder="您的联系电话" />
      <van-field v-model="form.weiXinOrQQ" placeholder="您的微信号或QQ号" />
      <van-field
        v-model="form.content"
        rows="2"
        type="textarea"
        placeholder="具体投诉建议内容"
        show-word-limit
      />
      <van-field name="uploader">
        <template #input>
          <van-uploader
            v-model="uploader"
            :max-count="5"
            accept=".gif,.jpg,.jpeg,.png,.GIF,.JPG,.PNG"
            :after-read="afterRead"
            :before-read="beforeRead"
          />
        </template>
      </van-field>
      <div style="margin: 10px;">
        <van-button
          type="success"
          round
          style="display:block;margin:auto;width:95%;"
          @click="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Grid, GridItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { Uploader } from "vant";
import { Button, Toast } from "vant";
//
import { doUpload, addSuggestion } from "@/config/_api";
export default {
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [VanImage.name]: VanImage,
    [Form.name]: Form,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
  },

  data() {
    return {
      form: {
        contactPhone: "",
        weiXinOrQQ: "",
        content: "",
        image: "",
      },
      uploader: [],
    };
  },

  created() {
    this.form = {
      contactPhone: "",
      weiXinOrQQ: "",
      content: "",
      image: "",
    };
  },

  methods: {
    async submit() {
      let { data } = await addSuggestion(this.form);
      Toast.success(data.msg);
      if (data.code != 0) {
        this.form = {
          contactPhone: "",
          weiXinOrQQ: "",
          content: "",
          image: "",
        };
      }
    },
    // async afterRead(file) {
    // 此时可以自行将文件上传至服务器
    // console.log(file);
    // console.log(file);
    // },
    async beforeRead(file) {
      console.log(file);
      let { data } = await doUpload({ file });
      console.log(data);
      // 1de51c5f1116021c7eb674001d4c2d9.png
      return false;
    },
  },
};
</script>

<style scoped>
.headtext {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}
</style>
